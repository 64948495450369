import classes from './EditPrompt.module.scss'

import { useEffect, useState, useRef } from 'react';

import {TimeOutHandler, decryptData} from '../../utils/helper_functions.js'

import { ImpulseSpinner } from 'react-spinners-kit';


const EditPrompt = () => {
    const elementRef = useRef(null);

    const API_URL = process.env.REACT_APP_API_URL
    const aes_salt_key = process.env.REACT_APP_AES_SALT
    const prompt_admin_access_token = decryptData(localStorage.getItem('prompt_admin_access_token', null), aes_salt_key)

    
    const prompt_uid = decryptData(localStorage.getItem('prompt_uid', null), aes_salt_key)
    const prompt_uuid = decryptData(localStorage.getItem('prompt_uuid', null), aes_salt_key)
    const prompt_chat_room =  localStorage.getItem('prompt_chat_room', '')
    const prompt_agent_name = localStorage.getItem('prompt_agent_name', 'natasha')
    
    const [showSuccessAlert, setShowSuccessAlert] = useState(false)
    const [showFailAlert, setShowFailAlert] = useState(false)
    const [deleteChats, setDeleteChats] = useState(false)

    const [initial, setInitial] = useState('')
    const [havent, setHavent] = useState('')
    const [extractChatContext, setExtractChatContext] = useState('')
    const [inactive, setInactive] = useState('')
    const [inactiveFollowup, setInactiveFollowup] = useState('')
    const [casual, setCasual] = useState('')

    const [userSummary, setUserSummary] = useState('')
    const [allSupported, setAllSupported] = useState('')
    const [cantReply, setCantReply] = useState('')

    const [followupWelcome, setFollowupWelcome] = useState('')
    const [welcome24, setWelcome24] = useState('')

    // const [followup, setFollowup] = useState('')
    // const [force, setForce] = useState('')

    // edit prompt page loading
    const [editLoading, setEditLoading] = useState(true)
    const [deleteChatsDisabled, setDeleteChatsDisabled] = useState(true)
    


    const get_prompt = async () => {
        console.log("getting prompt :", API_URL)
        const res = await fetch(`${API_URL}/api/v1/prompt`, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${prompt_admin_access_token}`
            }
        })
        const res_status = res.status
        const data = await res.json()
        console.log('prompt :', data)

        if(res_status === 200) {
            const prompt_data = data['prompt']
            setInitial(prompt_data['initial'])
            setHavent(prompt_data['havent'])
            setExtractChatContext(prompt_data['extract_chat_context'])
            setFollowupWelcome(prompt_data['followup_welcome'])
            setWelcome24(prompt_data['welcome_24'])
            setCasual(prompt_data['casual'])
            setInactive(prompt_data['inactive'])
            setInactiveFollowup(prompt_data['inactive_followup'])

            setUserSummary(prompt_data['user_summary_prompt'])
            setAllSupported(prompt_data['all_supported_prompt_names'])
            setCantReply(prompt_data['cant_reply_prompt'])

            // setFollowup(prompt_data['followup'])
            // setForce(prompt_data['force'])
        }else{
            console.log("Err in get initial prompt data:", res_status, data)
        }
        setEditLoading(false)
    }


    useEffect(()=>{
        get_prompt()

        console.log('edit prompt :', prompt_uid, prompt_uuid, prompt_chat_room, prompt_agent_name)
        if(prompt_uuid && prompt_chat_room){
            setDeleteChatsDisabled(false)
        }
    }, [])


        // console.log("new initial :", initial)
        // console.log("new havent :", havent)
        // console.log("new extractChatContext :", extractChatContext)
        // console.log("new followup :", followup)
        // console.log("new followup welcome :", followupWelcome)
        // console.log("new welcome 24 :", welcome24)
        // console.log("new force :", force)
    const handle_save_prompt = async() => {
        var prompt = {
            "initial" : initial,
            "havent" : havent,
            "extract_chat_context" : extractChatContext,
            "followup_welcome" : followupWelcome,
            "welcome_24" : welcome24,
            "casual" : casual,
            "inactive" : inactive,
            "inactive_followup": inactiveFollowup,

            "user_summary_prompt": userSummary,
            "all_supported_prompt_names": allSupported,
            "cant_reply_prompt": cantReply,
        }

        // "followup" : followup,
        // "force" : force
        
        setEditLoading(true)
        const res = await fetch(`${API_URL}/api/v1/prompt`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${prompt_admin_access_token}`,
            },
            body: JSON.stringify({'prompt':prompt})
        })

        const res_status = res.status
        const data = await res.json()
        console.log('returned prompt after update :', data['prompt'])

        if(res_status === 200){
            const prompt_data = data['prompt']
            setInitial(prompt_data['initial'])
            setHavent(prompt_data['havent'])
            setExtractChatContext(prompt_data['extract_chat_context'])
            setFollowupWelcome(prompt_data['followup_welcome'])
            setWelcome24(prompt_data['welcome_24'])
            setCasual(prompt_data['casual'])
            setInactive(prompt_data['inactive'])
            setInactiveFollowup(prompt_data['inactive_followup'])

            setUserSummary(prompt_data['user_summary_prompt'])
            setAllSupported(prompt_data['all_supported_prompt_names'])
            setCantReply(prompt_data['cant_reply_prompt'])

            // setFollowup(prompt_data['followup'])
            // setForce(prompt_data['force'])

            setShowSuccessAlert(true)
            TimeOutHandler(()=>{
                setShowSuccessAlert(false)
            }, 5000)

        }else{
            console.log("failed to update prompt", res_status, data)
            setShowFailAlert(true)
            TimeOutHandler(()=>{
                setShowFailAlert(false)
            }, 5000)
        }
        setEditLoading(false)
    }


    const handle_delete_chats = async () => {
        if(deleteChatsDisabled){return}

        console.log("Handle delete chats")
        setDeleteChats(true)
        setEditLoading(true)
        const res = await fetch(`${API_URL}/api/v1/chats`, {
            method: 'DELETE',
            headers:{
                'uid':prompt_uid,
                'uuid':prompt_uuid,
                'chatroom':prompt_chat_room,
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${prompt_admin_access_token}`,
            },
        })
        const res_status = res.status
        const data = await res.json()
        console.log('delete chats res :', data)

        if(res_status === 200) {
            setShowSuccessAlert(true)
            TimeOutHandler(()=>{
                setShowSuccessAlert(false)
                setDeleteChats(false)
            }, 5000)
        }else{
            console.log("failed to delete chats", res_status, data)
            setShowFailAlert(true)
            TimeOutHandler(()=>{
                setShowFailAlert(false)
                setDeleteChats(false)
            }, 5000)
        }
        setEditLoading(false)
    }



    const handleGoToTop = () => {
        elementRef.current.scrollIntoView({behavior: 'smooth'});
    }




    return (
            <div className={classes.edit_div}>
                <div className={classes.edit_div_form}>
                    <div className={classes.edit_div_heading} ref={elementRef}>
                        <div className={classes.edit_div_heading_left}>
                            <div className={classes.edit_div_heading_left_top}>
                                <h1>Edit Prompt</h1>
                                {editLoading && <ImpulseSpinner size={30} frontColor="#fff" backColor="#ffffff60"/>}

                                {showSuccessAlert && <p className={classes.green_text}>{deleteChats?"(Chats Deleted Successfully)":"(Operation Successful)"}</p>}
                                {showFailAlert && <p className={classes.red_text}>{deleteChats?"(Chat Deletion Failed)":"(Operation Failed)"}</p>}
                            </div>
                            <p>Astrowize astrology app (agent first). Prompt can be edited from here</p>
                        </div>
                        <div className={classes.edit_div_heading_right}>
                            <button onClick={handle_save_prompt}>✔ Save</button>
                            <p>♦︎</p>
                            <button onClick={handle_delete_chats} className={deleteChatsDisabled?classes.btn_disabled:classes.btn_red}>⏺ Delete Chats</button>
                        </div>
                    </div>

                    <div className={classes.each_input_div}>
                        <div className={classes.each_input_div_each}>
                            <div className={classes.label_div}>
                                <p>Initial Prompt</p>
                                <small>(When user first sends a message to agent)</small>
                            </div>

                            <div className={classes.input_div}>
                                <textarea onChange={(e) => setInitial(e.target.value)} value={initial}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className={classes.each_input_div}>
                        <div className={classes.each_input_div_each}>
                            <div className={classes.label_div}>
                                <p>Havent Hear From You Prompt</p>
                                <small>(When user never sent any msg and reloads the app)</small>
                            </div>
                            <div className={classes.input_div}>
                                <textarea onChange={(e) => setHavent(e.target.value)} value={havent}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className={classes.each_input_div}>
                        <div className={classes.each_input_div_each}>
                            <div className={classes.label_div}>
                                <p>Welcome Prompt After 24h</p>
                                <small>(When user never sent any msg but came back to app after 24 hours)</small>
                            </div>
                            <div className={classes.input_div}>
                                <textarea onChange={(e) => setWelcome24(e.target.value)} value={welcome24}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className={classes.each_input_div}>
                        <div className={classes.each_input_div_each}>
                            <div className={classes.label_div}>
                                <p>Followup Welcome Prompt</p>
                                <small>(User had chats and returned back to app again after 24 hours)</small>
                            </div>
                            <div className={classes.input_div}>
                                <textarea onChange={(e) => setFollowupWelcome(e.target.value)} value={followupWelcome}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className={classes.each_input_div}>
                        <div className={classes.each_input_div_each}>
                            <div className={classes.label_div}>
                                <p>Inactive User Prompt</p>
                                <small>(User is inactive for 5m)</small>
                            </div>
                            <div className={classes.input_div}>
                                <textarea onChange={(e) => setInactive(e.target.value)} value={inactive}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className={classes.each_input_div}>
                        <div className={classes.each_input_div_each}>
                            <div className={classes.label_div}>
                                <p>Inactive Followup Prompt</p>
                                <small>(User is inactive for 3m. So send him a followup msg to check out what he thinks about last msg)</small>
                            </div>
                            <div className={classes.input_div}>
                                <textarea onChange={(e) => setInactiveFollowup(e.target.value)} value={inactiveFollowup}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className={classes.each_input_div}>
                        <div className={classes.each_input_div_each}>
                            <div className={classes.label_div}>
                                <p>Casual Prompt</p>
                                <small>(Handles basic Q&A and reply basic responses)</small>
                            </div>
                            <div className={classes.input_div}>
                                <textarea onChange={(e) => setCasual(e.target.value)} value={casual}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className={classes.each_input_div}>
                        <div className={classes.each_input_div_each}>
                            <div className={classes.label_div}>
                                <p>Extract Chat Context Prompt</p>
                                <small>(Used to extract the chat context of user's message)</small>
                            </div>
                            <div className={classes.input_div}>
                                <textarea onChange={(e) => setExtractChatContext(e.target.value)} value={extractChatContext}></textarea>
                            </div>
                        </div>
                    </div>
                    {/* <div className={classes.each_input_div}>
                        <div className={classes.each_input_div_each}>
                            <div className={classes.label_div}>
                                <p>Followup Prompt</p>
                                <small>(Process user's msg and reply based on previous chatting)</small>
                            </div>
                            <div className={classes.input_div}>
                                <textarea onChange={(e) => setFollowup(e.target.value)} value={followup}></textarea>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className={classes.each_input_div}>
                        <div className={classes.each_input_div_each}>
                            <div className={classes.label_div}>
                                <p>Force Prompt</p>
                                <small>(User kept chatting so force him to jojo.co.il)</small>
                            </div>
                            <div className={classes.input_div}>
                                <textarea onChange={(e) => setForce(e.target.value)} value={force}></textarea>
                            </div>
                        </div>
                    </div> */}


                    <div className={classes.each_input_div}>
                        <div className={classes.each_input_div_each}>
                            <div className={classes.label_div}>
                                <p>User Summary Prompt</p>
                                <small>(Create user summary by using this prompt)</small>
                            </div>
                            <div className={classes.input_div}>
                                <textarea onChange={(e) => setUserSummary(e.target.value)} value={userSummary}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className={classes.each_input_div}>
                        <div className={classes.each_input_div_each}>
                            <div className={classes.label_div}>
                                <p>All Supported Prompt Context Names</p>
                                <small>(Must be in comma separated value)</small>
                            </div>
                            <div className={classes.input_div}>
                                <textarea onChange={(e) => setAllSupported(e.target.value)} value={allSupported}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className={classes.each_input_div}>
                        <div className={classes.each_input_div_each}>
                            <div className={classes.label_div}>
                                <p>Can't Reply Prompt</p>
                                <small>(If user send messages outside the context of "Supported Prompt Context", Then use this prompt to reply)</small>
                            </div>
                            <div className={classes.input_div}>
                                <textarea onChange={(e) => setCantReply(e.target.value)} value={cantReply}></textarea>
                            </div>
                        </div>
                    </div>

                    <button className={classes.go_to_top_btn} onClick={handleGoToTop}>↑ Go To Top 
                    {/* <ScrollToTop smooth /> */}
                    </button>
                </div>
            </div>
    )
}

export default EditPrompt;