import classes from './Checkout.module.scss'

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'

// import { TimeOutHandler, decryptData } from '../../utils/helper_functions.js'
import { GooSpinner, ImpulseSpinner } from 'react-spinners-kit';
import { CountrySelect, MonthSelect } from './select_components.js'

import lock_white_icon from '../../assets/icons/lock_white.svg'
import user_white_icon from '../../assets/icons/user_white.svg'
import document_white from '../../assets/icons/document_white.svg'
import cart_white_icon from '../../assets/icons/cart_white.svg'
import clock_white_icon from '../../assets/icons/clock_white.svg'




const Checkout = (props) => {
    const navigate = useNavigate()

    // loading states
    const [showLoading, setShowLoading] = useState(false)
    const [showCheckoutDiv, setShowCheckoutDiv] = useState(false)

    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');

    const [cardNumber, setCardNumber] = useState('');
    const [selectedMonth, setSelectedMonth] = useState();
    const [expirationYear, setExpirationYear] = useState(2024);
    const [securityCode, setSecurityCode] = useState('');

    const [selectedCountry, setSelectedCountry] = useState();

    

    // chat page alert states
    const [subscriptionMsg, setSubscriptionMsg] = useState('') 
    const [showSubscriptionSuccess, setShowSubscriptionSuccess] = useState(false) 
    const [showSubscriptionFailed, setShowSubscriptionFailed] = useState(false) 
    const supported_country_codes = ['US', 'AF', 'AL', 'DZ', 'AS', 'AD', 'AO', 'AI', 'AQ', 'AG', 'AR', 'AM', 'AW', 'AU', 'AT', 'AZ', 'BS', 'BH', 'BD', 'BB', 'BY', 'BE', 'BZ', 'BJ', 'BM', 'BT', 'BO', 'BA', 'BW', 'BV', 'BR', 'BN', 'BG', 'BF', 'BI', 'KH', 'CM', 'CA', 'CV', 'KY', 'CF', 'TD', 'CL', 'CN', 'CX', 'CC', 'CO', 'KM', 'CG', 'CK', 'CR', 'CI', 'HR', 'CU', 'CY', 'CZ', 'DK', 'DJ', 'DM', 'DO', 'TP', 'EC', 'EG', 'SV', 'GQ', 'ER', 'EE', 'ET', 'FK', 'FO', 'FJ', 'FI', 'FR', 'GF', 'PF', 'TF', 'GA', 'GM', 'GE', 'DE', 'GH', 'GI', 'GR', 'GL', 'GD', 'GP', 'GU', 'GT', 'GN', 'GW', 'GY', 'HT', 'HN', 'HK', 'HU', 'IS', 'IN', 'ID', 'IR', 'IQ', 'IE', 'IL', 'IT', 'JM', 'JP', 'JO', 'KZ', 'KE', 'KI', 'KP', 'KR', 'KW', 'KG', 'LA', 'LV', 'LB', 'LS', 'LR', 'LY', 'LI', 'LT', 'LU', 'MO', 'MK', 'MG', 'MW', 'MY', 'MV', 'ML', 'MT', 'MH', 'MQ', 'MR', 'MU', 'YT', 'MX', 'FM', 'MD', 'MC', 'MN', 'MS', 'MA', 'MZ', 'MM', 'NA', 'NR', 'NP', 'NL', 'AN', 'NC', 'NZ', 'NI', 'NE', 'NG', 'NU', 'NF', 'MP', 'NO', 'OM', 'PK', 'PW', 'PA', 'PG', 'PY', 'PE', 'PH', 'PN', 'PL', 'PT', 'PR', 'QA', 'RE', 'RO', 'RU', 'RW', 'KN', 'LC', 'VC', 'WS', 'SM', 'ST', 'SA', 'SN', 'SC', 'SL', 'SG', 'SK', 'SI', 'SB', 'SO', 'ZA', 'ES', 'LK', 'SH', 'PM', 'SD', 'SR', 'SZ', 'SE', 'CH', 'SY', 'TW', 'TJ', 'TZ', 'TH', 'TG', 'TK', 'TO', 'TT', 'TN', 'TR', 'TM', 'TV', 'UG', 'UA', 'AE', 'UK', 'UY', 'UZ', 'VU', 'VA', 'VE', 'VN', 'VG', 'VI', 'EH', 'YE', 'YU', 'ZR', 'ZM', 'ZW']

    useEffect(()=>{
        get_user_data()
    }, [])


    const get_user_data = async () => {
        const res = await fetch(`${props.API_URL}/api/v2/user`, {
            method: 'GET',
            headers: {'Authorization':`Bearer ${props.prompt_access_token}`}
        })

        const res_status = res.status
        const data = await res.json()
        console.log('\n\n\nuser email and username res :', res_status, data)
        if(res_status===200){
            setUsername(data['user_name'])
            setEmail(data['user_email'])
            setShowCheckoutDiv(true)
        }else{
            if(res_status===401){
                // access token expired so lets send the user to login page
                props.remove_local_storage()
                localStorage.setItem('show_subscription_page', false)
                navigate('/login')
            }
        }
    }


    // const verify_purchase_status = async () => {
    //     console.log("Get user's email and username from backend:", props.prompt_uuid, props.prompt_chatroom_key)
    //     const res = await fetch(`${props.API_URL}/api/v2/purchase`, {
    //         method: 'GET',
    //         headers: {'Authorization':`Bearer ${props.prompt_access_token}`}
    //     })

    //     const res_status = res.status
    //     const data = await res.json()
    //     console.log('\n\n\nuser email and username res :', res_status, data)
    //     if(res_status===200){
    //         setSubscriptionMsg(data['msg'])
    //         setShowSubscriptionSuccess(true)
    //         setUsername(data['user_name'])
    //         setEmail(data['user_email'])

    //         if(data['show_checkout'] === true){
    //             // localStorage.setItem('show_subscription_page', true)
    //             setShowCheckoutDiv(true)
    //         }else{
    //             localStorage.setItem('show_subscription_page', false)
    //             navigate(`/agent/${props.prompt_agent_name}/${props.prompt_chatroom_key}`)
    //         }
    //         setTimeout(() => {setShowSubscriptionSuccess(false)}, 3000);
    //     }else{
    //         setSubscriptionMsg(res['error'])
    //         setShowSubscriptionFailed(true)
    //         setTimeout(() => {setShowSubscriptionFailed(false)}, 3000);
    //     }
    // }


    const handleProcessPayment = async () => {
        if(showLoading){return}

        setShowLoading(true)
        console.log("payment process clicked :", email, username, cardNumber, selectedMonth, expirationYear, securityCode, selectedCountry, supported_country_codes.includes(selectedCountry))
        console.log(typeof email, typeof username, typeof cardNumber, typeof selectedMonth, typeof expirationYear, typeof securityCode, typeof selectedCountry)
        console.log(cardNumber.length, 16<cardNumber.length || cardNumber.length<15, selectedMonth<=0, 2099<expirationYear && expirationYear<1950, 9999<securityCode.length<0, !supported_country_codes.includes(selectedCountry))

        if(email==='' || username==='' || cardNumber==='' || 16<cardNumber.length || cardNumber.length<15 || selectedMonth<=0 || (2099<expirationYear && expirationYear<1950) || 9999<securityCode.length<0 || !supported_country_codes.includes(selectedCountry)){
            setSubscriptionMsg("Invalid data")
            setShowSubscriptionFailed(true)
            setShowLoading(false)
            setTimeout(() => {setShowSubscriptionFailed(false) }, 3000)
            return
        }

        const res = await fetch(`${props.API_URL}/api/v2/purchase`, {
            method: 'POST',
            headers: {
                'Authorization':`Bearer ${props.prompt_access_token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_email: email,
                user_name: username,
                card_number: cardNumber,
                card_validity_month: selectedMonth,
                card_validity_year: expirationYear,
                cvv: securityCode,
                country: selectedCountry
            })
        })

        const res_status = res.status
        const data = await res.json()
        console.log('\n\n\npurchase res :', res_status, data)
        if(res_status===200){
            setSubscriptionMsg(data['msg'])
            setShowSubscriptionSuccess(true)
            localStorage.setItem('show_subscription_page', false)

            setTimeout(() => {
                setShowSubscriptionSuccess(false)
                window.location.href = `/agent/${props.prompt_agent_name}/${props.prompt_chatroom_key}`   
            }, 3000);
        }else{
            if(res_status===401){
                // access token expired so lets send the user to login page
                props.remove_local_storage()
                localStorage.setItem('show_subscription_page', false)
                navigate('/login')
            }else{
                // internet issue or something went wrong
                setSubscriptionMsg(data['error'])
                setShowSubscriptionFailed(true)
                setShowLoading(false)

                setTimeout(() => {setShowSubscriptionFailed(false)}, 3000)
            }
        }
    }
    
    return (
        <div className={classes.container}>
            <div className={classes.checkout_container}>
                {!showCheckoutDiv?(<GooSpinner/>):(<>
                <div className={classes.checkout_container_top}>
                    <div><img src={lock_white_icon}/></div>
                    <div>Checkout</div>
                </div>

                <div className={classes.checkout_container_middle}>
                    <div className={classes.checkout_container_div}>
                        <div className={classes.checkout_container_div_each}> 
                            <div className={classes.checkout_row_div}>
                                <img src={user_white_icon} alt="" />
                                <div className={classes.heading_div}>Customer Information</div>
                            </div>
                            <div className={classes.checkout_column_div}>
                                <div>Email Address</div>
                                <input 
                                    className={classes.checkout_input} 
                                    type="text" 
                                    placeholder={email?`${email}`:'you@mail.com'} 
                                    value={email}
                                    onChange={(e)=>setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={classes.checkout_container_div_each}>
                            <div className={classes.checkout_row_div}>
                                <img src={document_white} alt=""/>
                                <div className={classes.heading_div}>Billing Information</div>
                            </div>
                            <div className={classes.checkout_column_div}>
                                <div>Payment Method (Credit Card)</div>
                            </div>
                            <div className={classes.checkout_column_div}>
                                <div>Cardholder Name</div>
                                <input 
                                    className={classes.checkout_input} 
                                    type="text" 
                                    placeholder={username?`${username}`:"Cardholder Name"}
                                    value={username}
                                    onChange={(e)=>setUsername(e.target.value)}
                                />
                            </div>
                            <div className={classes.checkout_column_div}>
                                <div>Card Number</div>
                                <input 
                                    className={classes.checkout_input} 
                                    type="text" 
                                    placeholder='1234-4567-8765'
                                    value={cardNumber}
                                    onChange={(e)=>setCardNumber(e.target.value)} 
                                />
                            </div>

                            <div className={classes.checkout_row_div}>
                                <div className={classes.checkout_column_div}>
                                    <div>Expiration Month</div>
                                    <MonthSelect setSelectedMonth={setSelectedMonth}/>      
                                </div>
                                <div className={classes.checkout_column_div}>
                                    <div>Expiration Year</div>
                                    <input 
                                        className={classes.checkout_input} 
                                        type="number" 
                                        min="1950" 
                                        max="2099"
                                        step="1" 
                                        placeholder='2024'
                                        value={expirationYear}
                                        onChange={(e)=>setExpirationYear(e.target.value)} 
                                    />
                                </div>
                                <div className={classes.checkout_column_div}>
                                    <div>Security Code</div>
                                    <input 
                                    className={classes.checkout_input} 
                                    type="number"
                                    placeholder='CVV'
                                    value={securityCode}
                                    onChange={(e)=>setSecurityCode(parseInt(e.target.value))} 
                                    />
                                </div>
                            </div>
                            <div className={classes.checkout_column_div}>
                                <div>Country</div>
                                <CountrySelect setSelectedCountry={setSelectedCountry}/>
                                </div>
                        </div>
                    </div>

                    <div className={classes.checkout_container_div}>
                        <div className={classes.checkout_container_div_each}>
                            <div className={classes.checkout_row_div}>
                                <img src={cart_white_icon} alt=""/>
                                <div className={classes.heading_div}>Order Summary</div>
                            </div>

                        </div>
                        <div className={classes.checkout_container_div_each}>
                            <div className={classes.checkout_column_div}>
                                <div>Astrowize Subscription</div>
                                <div className={classes.subheading_div}>
                                    Get subscription plan and enjoy unlimited astrological & spiritual experience.
                                </div>
                            </div>

                            <input className={classes.checkout_input} disabled type="text" value="US - US Dollar"/>
                            <div className={classes.row_div}>
                                <div className={classes.subheading_div}>Subtotal</div>
                                <div className={classes.subheading_div}>$10.00</div>
                            </div>
                            <div className={classes.row_div}>
                                <div className={classes.subheading_div}>Tax</div>
                                <div className={classes.subheading_div}>$0.00</div>
                            </div>
                            <div className={classes.row_div}>
                                <div className={classes.subheading_div}>Today's Payment</div>
                                <div className={classes.subheading_div}>$10.00</div>
                            </div>
                        </div>
                        <div className={classes.checkout_container_div_each}>
                            <div className={classes.checkout_column_div}>
                                <div className={classes.subheading_div}>Immediate access to this product or service is available once payment is approved.</div>
                            </div> 
                        </div>
                        <div className={classes.checkout_container_div_each}>
                            <div className={classes.checkout_row_div}>
                                <img src={clock_white_icon} alt=""/>
                                <div className={classes.heading_div}>Future Payments</div>
                            </div>
                            <div className={classes.subheading_div}>Monthly Payment of $10.00 *</div>
                            {/* <div className={classes.subheading_div}>* Next payment will be billed on Feb 25, 2024.<br/>Amount includes taxes subject to change based on customer location and rate adjustments.</div> */}
                        </div>

                        <div className={classes.checkout_container_div_each}>
                            <button className={classes.process_btn} onClick={handleProcessPayment}>{showLoading?<ImpulseSpinner size={30} frontColor="#fff" backColor="#ffffff60"/>:"Process Payment"}</button>
                            <div className={classes.subheading_div}>Copyright 2024 © Astrowize</div>
                        </div>

                        {showSubscriptionSuccess && <div className={classes.success_alert}>{subscriptionMsg}</div>}
                        {showSubscriptionFailed && <div className={classes.failed_alert}>{subscriptionMsg}</div>}
                        </div>
                    </div>
                </>)}
            </div>
        </div>
    )
}

export default Checkout;