import classes from './Chat.module.scss'
import natasha from '../../assets/images/natasha.png'

import useWindowSize from '../../assets/hooks/useWindowSize.js'
import { TimeOutHandler, decryptData } from '../../utils/helper_functions'

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from 'react-router-dom'

import { ImpulseSpinner } from "react-spinners-kit";
import parse from 'html-react-parser';



const Chat = (props) => {
    const params = useParams()
    const navigate = useNavigate()

    const ref = useRef(null)
    const API_URL = process.env.REACT_APP_API_URL
    const aes_salt_key = process.env.REACT_APP_AES_SALT
    const agent_name = params.agent_name
    let {width, height} = useWindowSize()

    const prompt_uuid = decryptData(localStorage.getItem('prompt_uuid', null), aes_salt_key)
    const prompt_access_token = decryptData(localStorage.getItem('prompt_access_token', null), aes_salt_key)
    const prompt_chatroom_key = localStorage.getItem('prompt_chat_room', '')

    const [chats, setChats] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [reloadAppText, setReloadAppText] = useState(false)
    const [msg, setMsg] = useState('')
    const [newMsg, setNewMsg] = useState('')

    // followup for get
    // const [getFollowup, setGetFollowup] = useState(false)    

    
    const [msgSendInProgress, setMsgSendInProgress] = useState(false)
    const [lastDate, setLastDate] = useState('')

    const [isChatDisconnected, setIsChatDisconnected] = useState(false)
    const [forceToSubscribe, setForceToSubscribe] = useState(false)
    const [isMsgEmpty, setIsMsgEmpty] = useState(true)
    const [msgSentError, setMsgSentError] = useState(false)    


    // websocket events and handler functions, useEffect
    // const onSignatureErr = async (value) => {
    //     console.log("SOCKET ERROR :", value)
    //     if(msgSendInProgress){
    //         setInsertChats(false) //dont insert chats anymore in chats array
    //         console.log("token expired when sending")
    //         await get_new_access_token(prompt_uuid, prompt_access_token, prompt_chatroom_key, API_URL, aes_salt_key)
    //         setAccessTokenChanged(true)
    //         // handleMsgSend()
    //         setMsgSendInProgress(false)
    //     }else{
    //         console.log("token expired before socket connected :")
    //         await get_new_access_token(prompt_uuid, prompt_access_token, prompt_chatroom_key, API_URL, aes_salt_key)
    //         setAccessTokenChanged(true)
    //         // socket.emit('reconnect')
    //     }
    // }
    // const onError = async (value) => {
    //     console.log("ERROR - maybe access token, chat room token or uuid deleted :", value)
    //     await get_new_access_token(prompt_uuid, prompt_access_token, prompt_chatroom_key, API_URL, aes_salt_key)
    //     setAccessTokenChanged(true)
    // }

    // ################################# functions #################################
    // function onInitialMessageReceived(value){
    //     const all_chats = JSON.parse(value)
    //     setChats(all_chats)
    //     setIsLoading(false)

    //     if(all_chats[all_chats.length - 1]['agent_status']=== 'INACTIVE'){
    //         console.log("WS Disconnected. so disabling input")
    //         setIsChatDisconnected(true)
    //     }
    // }
    // function onInitialMessageReceivedClosed(){
    //     console.log("Initialization closed")
    // }


    // function onMessageReceived(value){
    //     if(!msgSentError){
    //         setIsLoading(false)
    //         const sent_msg = JSON.parse(value)
    //         setChats([...chats, sent_msg])

    //         if(sent_msg['agent_status'] === 'INACTIVE'){
    //             console.log("WS Disconnected. so disabling input")
    //             setIsChatDisconnected(true)
    //         }
    //     }
    // }
    // function onMessageReceivedClosed(){
    //     console.log("Connection closed")
    // }

    // useEffect(()=>{
    //     // console.log("SOCKET IN INITIALIZATION IS :", socket)
    //     socket.on('initialization', onInitialMessageReceived);
    //     return () => {
    //         socket.off('initialization', onInitialMessageReceivedClosed);
    //     }
    // }, [socket])

    // useEffect(() => {
    //     // console.log("Now chats are :", chats)
    //     socket.on('msg_from_server', onMessageReceived);
    //     return () => {
    //         socket.off('msg_from_server', onMessageReceivedClosed);
    //     }
    // }, [socket, chats])



    const get_initial_msg = async() => {
        console.log("getting initial msg astrowize-astrology :", API_URL, prompt_uuid, prompt_chatroom_key)

        const res = await fetch(`${API_URL}/api/v1/ws`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${prompt_access_token}`,
                'uuid':prompt_uuid,
                'chattoken':prompt_chatroom_key,
                // 'inactive_followup':get_inactive_followup,
                // 'add_msg_at_last':add_msg_at_last,
            }
        })
        const res_status = res.status
        const data = await res.json()
        console.log('initial chats astrowize-astrology :', data)
        if(res_status === 200) {
            const data_chats = data['chats']
            setChats(data_chats)
            setIsLoading(false)

            if(data_chats.length>0){
                const last_msg_status = data_chats[data_chats.length-1]['agent_status']
                console.log("last_msg_status is :", last_msg_status)
                if(last_msg_status==='INACTIVE'){
                    setIsChatDisconnected(true)
                }else if(last_msg_status==='SUBSCRIBE'){
                    setForceToSubscribe(true)
                }
            }
        }else{
            setIsLoading(false)
            setReloadAppText(true)
            console.log("failed to get initial chats astrowize-astrology :", res_status, data)
        }
    }

    useEffect(()=>{
        get_initial_msg()
        // if(!getFollowup){setGetFollowup(true)}
    }, [])



    // useEffect(()=>{
    //     if(getFollowup){
    //         console.log("\n\n\nHERE :", getFollowup)
    //         // get followup msg after 3 minutes
    //         setTimeout(()=>{
    //             console.log("INACTIVE FOLLOWUP :", getFollowup)
    //             if(getFollowup){
    //                 setIsLoading(true)
    //                 get_initial_msg(true, true)
    //             }
    //         }, 10000)

    //         // get inactive msg after 5 minutes
    //         setTimeout(()=>{
    //             console.log("INACTIVE :", getFollowup)
    //             if(getFollowup){
    //                 setIsLoading(true)
    //                 get_initial_msg(true, false)
    //             }
    //         }, 15000)
    //     }
    // }, [getFollowup])

    // scroll to bottom functionality
    const scrollToBottom = () => {
        var lastChildElement;
        if(isLoading){
            lastChildElement = ref.current?.lastElementChild.previousSibling;
        }else{
            lastChildElement = ref.current?.lastElementChild;
        }
        // console.log("Last child :", lastChildElement, isLoading)
        lastChildElement?.scrollIntoView({ behavior: 'smooth' });   
    }


    useEffect(()=>{
        if(typeof newMsg === 'object'){
            setChats([...chats, newMsg])

            if(newMsg['agent_status']==='SUBSCRIBE'){
                setForceToSubscribe(true)
            }
        }
    }, [newMsg])

    useEffect(()=>{
        scrollToBottom()
    }, [chats])
    
    // type and send msg functionality
    const handleMsgSend = async() => {
        setMsgSentError(false)
        // setGetFollowup(false)

        window.addEventListener("resize", scrollToBottom);

        if(!isMsgEmpty && msg!=='' && !isLoading){
            var when;
            if(msgSendInProgress){
                when = lastDate
            }else{
                const date = new Date()
                when = date.getHours()+":"+date.getMinutes()
                setLastDate(when)
            }
            console.log("msg send again :", when)

            var msg_to_be_sent = {
                from: prompt_chatroom_key,
                msg: msg,
                when: when,
                status: 'delivered'
            }
            
            setChats([...chats, msg_to_be_sent])
            setMsg('')
            setIsLoading(true)

            const res = await fetch(`${API_URL}/api/v1/ws`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${prompt_access_token}`,
                },
                body: JSON.stringify({'msg_to_be_sent':msg_to_be_sent})
            })
    
            const res_status = res.status
            const data = await res.json()
            console.log('returned res chats :', data['chat'])
            if(res_status === 200) {
                setNewMsg(data['chat'])
                setIsLoading(false)
    
                if(data['close_connection']===true){
                    TimeOutHandler(()=>{
                        setIsChatDisconnected(true)
                    }, 3000)
                }

                // // request POST followup msg after 3 minutes
                // setTimeout(()=>{
                //     console.log("INACTIVE FOLLOWUP in POST:", getFollowup)
                //     if(!getFollowup){
                //         setIsLoading(true)
                //         get_initial_msg(true, true)
                //     }
                // }, 30000)
                // // request POST inactive msg after 5 minutes
                // setTimeout(()=>{
                //     console.log("INACTIVE in POST :", getFollowup)
                //     if(!getFollowup){
                //         setIsLoading(true)
                //         get_initial_msg(true, false)
                //     }
                // }, 45000)
            }else{
                setIsLoading(false)
                console.log("failed to send subsequent chats", res_status, data)
            }
        }
    }



    const handleKeyDown = (event) => {
        console.log("----------------------------------")
        if (event.key === 'Enter') {
            if(forceToSubscribe){
                props.verify_purchase()
            }else{
                handleMsgSend()
            }
        }
    }

    const handleMsgInput = (e) => {
        const input_msg = e.target.value
        setMsg(input_msg)

        if(input_msg===''){
            setIsMsgEmpty(true)
        }else{
            setIsMsgEmpty(false)
        }
    }

    // const process_msg = (msg) => {
    //     if(msg.includes('https://jojo.co.il')){
    //         console.log("MSG includes url")
    //         const msg_list = msg.split('https://jojo.co.il')
    //         return <>
    //             {msg_list[0]}
    //             <a className={classes.anchor_tag} href="https://jojo.co.il" rel="noreferrer" target="_blank">https://jojo.co.il</a>
    //             {msg_list[msg_list.length-1]}
    //         </>        
    //     }else{
    //         return msg
    //     }
    // }

    

    const get_offline_placeholder = () => {
        return <p>{`${agent_name.charAt(0).toUpperCase() + agent_name.slice(1)} is offline now. Reach her at:`}<a className={classes.anchor_tag_secondary} href="https://jojo.co.il" rel="noreferrer" target="_blank">https://jojo.co.il</a></p> 
    }




    // const verify_purchase = async () => {
    //     console.log("verify purchase:")
    //     const res = await fetch(`${API_URL}/api/v2/purchase`, {
    //         method: 'GET',
    //         headers: {'Authorization':`Bearer ${prompt_access_token}`}
    //     })

    //     const res_status = res.status
    //     const data = await res.json()
    //     console.log('\n\n\nverify purchase res :', res_status, data)
    //     if(res_status===200){
    //         if(data['show_checkout'] === true){
    //             localStorage.setItem('show_subscription_page', true)
    //             navigate('/checkout')
    //         }
    //     }else{
    //         if(res_status===401){
    //             // access token expired so lets send the user to login page
    //             props.remove_local_storage()
    //             navigate('/login')
    //         }
    //     }
    // }

    // const handleNavigateToCheckout = () => {verify_purchase()}

    // const toggleSubscriptionPlanDiv = () =>{
    //     setShowSubscriptionDiv(!showSubscriptionDiv)
    // }


    return(
        <div className={classes.chat_div}>
            <div className={classes.chat_div_main}>
                {(width >= 992) && (width >= height) &&
                    <div className={classes.chat_div_main_left}>
                        <img src={natasha} alt=''/>
                    </div>
                }

                {/* chat section */}
                <div className={classes.chat_div_main_right}>
                    <div className={classes.chat_div_chat}>
                        <div className={classes.chat_div_chat_scroll}>
                            <div ref={ref} className={classes.chat_div_chat_scroll_100}>
                                {/* map all chats to div */}
                                {chats.map((item, index) => 
                                <div key={index}>
                                    {item['from'] === 'agent'?
                                        (
                                            <div className={classes.each_chat_div_parent_left}>
                                                <div className={classes.each_chat_div_left}>
                                                    {(width >= 992) && (width >= height)?
                                                    (
                                                        <>
                                                            <div className={classes.each_chat_div_left_profile}>
                                                                <div className={classes.chat_div_chat_icon}>
                                                                    <img src={natasha} alt='natasha'/>
                                                                </div>
                                                                <div className={(item['agent_status']==='INACTIVE' && isChatDisconnected)?classes.inactive_status: classes.active_status}></div>
                                                            </div>
                                                            <div className={classes.chat_div_chat_msg}>
                                                                { parse(item['msg'])}
                                                                {item['agent_status']==='SUBSCRIBE' && <div className={classes.subscription_plan_div}>Get <span> Subscription Plan</span> at $10/month to send Unlimited messages.<br/></div>}
                                                                    {/* subscription plans are:<br/><br/> */}
                                                                    {/* 2. <span>GOLD</span>: $10/month - Up to 1000 messages<br/>
                                                                    3. <span>PREMIUM</span> - $25/month - Up to 3000 messages.<br/> */}
                                                                    {/* <div onClick={toggleSubscriptionPlanDiv}>Checkout</div> */}
                                                            </div>
                                                            <div className={classes.chat_div_chat_msg_time_status_div}>
                                                                <div className={classes.chat_div_chat_msg_time}>
                                                                    {item['when']}
                                                                </div>
                                                                <div className={item['status']==='sent'?classes.chat_div_chat_msg_status_gray:classes.chat_div_chat_msg_status}>
                                                                    ✓✓
                                                                </div>
                                                            </div>
                                                        </>
                                                    ):(
                                                        <>
                                                            <div className={classes.each_chat_div_left_profile_mobile}>
                                                                <div className={classes.chat_div_chat_icon}>
                                                                    <img src={natasha} alt='natasha'/>
                                                                </div>
                                                                <div className={(item['agent_status']==='INACTIVE' && isChatDisconnected)?classes.inactive_status: classes.active_status}></div>

                                                                <div className={classes.chat_div_chat_msg_time_status_div_mobile}>
                                                                    <div className={classes.chat_div_chat_msg_time}>
                                                                        {item['when']}
                                                                    </div>
                                                                    <div className={item['status']==='sent'?classes.chat_div_chat_msg_status_gray:classes.chat_div_chat_msg_status}>
                                                                        ✓✓
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={classes.chat_div_chat_msg}>
                                                                { parse(item['msg'])}
                                                                { item['agent_status']==='SUBSCRIBE' && <div className={classes.subscription_plan_div}>Get <span> Subscription Plan</span> at $10/month to send Unlimited messages.<br/></div>}
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        ):(
                                            <div className={classes.each_chat_div_parent_right}>
                                                <div className={`${classes.each_chat_div_right}`}>
                                                    <div className={classes.chat_div_chat_msg}>
                                                        { parse(item['msg'])}
                                                    </div>
                                                    <div className={(width >= 992) && (width >= height)? classes.chat_div_chat_msg_time_status_div: classes.chat_div_chat_msg_time_status_div_mobile}>
                                                        <div className={classes.chat_div_chat_msg_time}>
                                                            {item['when']}
                                                        </div>
                                                        <div className={item['status']==='sent'?classes.chat_div_chat_msg_status_gray:classes.chat_div_chat_msg_status}>
                                                            ✓✓
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )} 
                                </div>)}


                                {/* typing animation when agent is producing result */}
                                {(isLoading && chats.length === 0) ? (
                                    <div className={classes.each_chat_div_parent_center}>
                                        <div className={classes.text_spinner}>
                                            <ImpulseSpinner size={30} frontColor="#fff" backColor="#ffffff60"/>
                                        </div>
                                    </div>
                                ):(
                                    <>
                                        {isLoading && 
                                        <div className={classes.each_chat_div_parent_left}>
                                            <div className={classes.each_chat_div_left}>
                                                <div className={classes.each_chat_div_left_profile}>
                                                    <div className={classes.chat_div_chat_icon}>
                                                        <img src={natasha} alt='natasha'/>
                                                    </div>
                                                    <div className={classes.active_status}></div>
                                                </div>
                                                <div className={classes.text_spinner}>
                                                    <>
                                                    <ImpulseSpinner size={30} frontColor="#ffffff80"/>
                                                    </>
                                                </div>
                                            </div>
                                        </div>}
                                    </>
                                )}

                                {reloadAppText && 
                                    <div className={classes.each_chat_div_parent_left}>
                                        <div className={classes.each_chat_div_left}>
                                            <div className={classes.each_chat_div_left_profile}>
                                                <div className={classes.chat_div_chat_icon}>
                                                    <img src={natasha} alt='natasha'/>
                                                </div>
                                                <div className={classes.active_status}></div>
                                            </div>
                                            <div className={classes.chat_div_chat_msg}>
                                                Something went wrong. Please reload the app.
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        {/* buttom input or jojo.co.il section for desktop */}
                        {(width >= 992) && (width >= height) && (
                            <>
                            {isChatDisconnected?(
                                <div className={classes.input_div_inactive}>
                                    {get_offline_placeholder()}
                                </div>
                            ):(
                                <div className={classes.input_div}>
                                    {forceToSubscribe?(
                                        <>
                                        <input disabled type='text' placeholder={`Click "GET IT" to purchase and continue.`} onKeyDown={handleKeyDown}/>
                                        <button onClick={props.verify_purchase}>{isLoading?<ImpulseSpinner size={30} frontColor="#fff" backColor="#ffffff60"/>:"GET IT"}</button>
                                        </>
                                    ):(
                                        <>
                                        <input type='text' placeholder={`Send message to ${agent_name}!`} value={msg} onKeyDown={handleKeyDown} onChange={handleMsgInput}/>
                                        <button onClick={handleMsgSend}>{isLoading?<ImpulseSpinner size={30} frontColor="#fff" backColor="#ffffff60"/>:"SEND"}</button>
                                        </>
                                    )}
                                </div>
                            )}
                            </>                
                            )}
                    </div>
                </div>
            </div>

            {/* buttom input or jojo.co.il section for mobile */}
            {(width <= 991) && (width <= height) && (
                <>
                {isChatDisconnected?(
                    <div className={classes.input_div_inactive}>
                        {get_offline_placeholder()}
                    </div>
                ):(
                    <div className={classes.input_div}>
                        {/* uncomment */}
                        {forceToSubscribe?(
                            <>
                            <input disabled type='text' placeholder={`Click "GET IT" to purchase and continue.`} onKeyDown={handleKeyDown}/>
                            <button onClick={props.verify_purchase}>{isLoading?<ImpulseSpinner size={30} frontColor="#fff" backColor="#ffffff60"/>:"GET IT"}</button>
                            </>
                        ):(
                            <>
                            {chats.length === 0 ? (<input type='text' placeholder={`Say Hello to ${agent_name}!`} value={msg} onKeyDown={handleKeyDown} onChange={handleMsgInput}/>):(<input type='text' placeholder={`Send message to ${agent_name}!`} value={msg} onKeyDown={handleKeyDown} onChange={handleMsgInput}/>)}
                            <button onClick={handleMsgSend}>{isLoading?<ImpulseSpinner size={30} frontColor="#fff" backColor="#ffffff60"/>:"SEND"}</button>
                            </>
                        )}
                    </div>
                )}
                </>                
            )}
        </div>
    )
}
export default Chat;




// {/* delete */}
// {/* <input type='text' placeholder={`${height} ${uiHeight} ${extraPadding} ${topPaddingFlag} ${(((width <= 991) && (width <= height)) && addExtraPadding)? 'extra': 'normal'}`} value={msg} onKeyDown={handleKeyDown} onChange={handleMsgInput}/>   */}
// {/* <div ref={ref} className={(((width <= 991) && (width <= height)) && addExtraPadding && extraPadding)? classes.chat_div_chat_scroll_100_extra_padding: (topPaddingFlag? `${classes.chat_div_chat_scroll_100} ${classes.extra_padding}`: classes.chat_div_chat_scroll_100)}> */}

// const [uiHeight, setUiHeight] = useState(0)
// const [addExtraPadding, setAddExtraPadding] = useState(false)
// const [extraPadding, setExtraPadding] = useState(false)
// const [topPaddingFlag, setTopPaddingFlag] = useState(true)

// useEffect(()=>{
//     if(uiHeight !== 0){
//         // alert(`${uiHeight} ${height} ${extraPadding} ${topPaddingFlag}`)

//         if(height > uiHeight){
//             setAddExtraPadding(false)
//             setTopPaddingFlag(false)

//         }else if(height < uiHeight){
//             setAddExtraPadding(true)
//             setTopPaddingFlag(false)
//         }

//         // setTimeout(() => {
//         //     setTopPaddingFlag(false)
//         // }, 5000);
//         if(!extraPadding){
//             setExtraPadding(true)
//         }
//     }

//     if(height !== 'undefined'){
//         // alert(`settting ui height ${height}`)
//         setUiHeight(height)
//     }
// }, [height])

// useEffect(()=>{
//     console.log("initial :", height > uiHeight, height < uiHeight)
//     // alert(`uiheight : ${uiHeight}`)
// }, [uiHeight])