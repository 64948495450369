import classes from './AdminDashboard.module.scss'

import NavBar from "../../components/NavBar/NavBar.js";
import EditPrompt from '../EditPrompt/EditPrompt.js'

import { useState } from 'react'
import { useDispatch } from 'react-redux';

import { uiActions } from '../../store/ui-slice'
import { decryptData } from '../../utils/helper_functions'
import { useNavigate } from 'react-router-dom';



const AdminDashboard = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    // navbar page states
    const [showSlider, setShowSlider] = useState(false)

    const API_URL = process.env.REACT_APP_API_URL
    const aes_salt_key = process.env.REACT_APP_AES_SALT
    const prompt_access_token = decryptData(localStorage.getItem('prompt_access_token', null), aes_salt_key)


    const verify_purchase = async () => {
        console.log("verify purchase:")
        const res = await fetch(`${API_URL}/api/v2/purchase`, {
            method: 'GET',
            headers: {'Authorization':`Bearer ${prompt_access_token}`}
        })

        const res_status = res.status
        const data = await res.json()
        console.log('\n\n\nverify purchase res :', res_status, data)
        if(res_status===200){
            if(data['show_checkout'] === true){
                localStorage.setItem('show_subscription_page', true)
                setShowSlider(false)
                navigate('/checkout')
            }
        }else{
            if(res_status===401){
                // access token expired so lets send the user to login page
                remove_local_storage()
                navigate('/login')
            }
        }
    }

    const remove_local_storage = () => {
        localStorage.removeItem('prompt_uid')
        localStorage.removeItem('prompt_uuid')
        localStorage.removeItem('prompt_access_token')
        localStorage.removeItem('prompt_agent_name')
        localStorage.removeItem('prompt_chat_room')
        localStorage.removeItem("verify_subscription")
        dispatch(uiActions.setIsLoggedIn(false))
    }

    const navbar_props = {
        verify_purchase,
        remove_local_storage,
        setShowSlider,
        showSlider,
     }

    return(
        <>
            <div className={classes.prompt_container}>
                <NavBar {...navbar_props}/>
                <EditPrompt/> 
            </div>
        </>
    )
}
export default AdminDashboard;